import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from '_store';
import { StudyList } from 'studylist';
import { Patient } from 'patient';
import { ImageViewer } from 'imageviewer';
import { Profile } from 'profile';
import { Center } from 'center';
import { Rate } from 'rate';
import { Invoice } from 'invoice';
import { Dashboard } from 'dashboard';
import { Nav, Menu, Footer, PrivateRoute } from '_components';

export { Home };

function Home(props) {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector(x => x.auth);
    const { users } = useSelector(x => x.users);
    
    const showHideMenu = (event) => {
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    }

    const refreshToken = (event) => {
        //alert('parent');
        const url = `${process.env.REACT_APP_API_URL}/users/authenticate`;
        const config = {
            headers: {
            'content-type': 'application/json'
            },
        };

        const token = JSON.parse(localStorage.getItem('user')).token;

        const data = new FormData();
        data.append(`Username`, authUser?.user_name);
        data.append(`token`, token);
            
        /*axios.post(url, data, config).then((response) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response.data));
        });*/
    }

    useEffect(() => {
        dispatch(userActions.getAll());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ReturnMenu = () => {
        //var lastPageName = localStorage.getItem('pagename');
        localStorage.setItem('pagename', props.pagename);
        //alert(props.pagename + '-' + lastPageName);
        
        switch(props.pagename){

            case "StudyList":
                return <div id="divStudyList"><StudyList refreshToken={refreshToken} /></div>;
            case "Patient":
                return <div id="divPatient"><Patient refreshToken={refreshToken} /></div>;
            case "Center":
                return <div id="divCenter"><Center /></div>;
            case "Rate":
                return <div id="divRate"><Rate /></div>;
            case "ImageViewer":
                return <div id="divImageViewer"><ImageViewer /></div>;
            case "Profile":
                return <div id="divProfile"><Profile /></div>;
            case "Invoice":
                return <div id="divInvoice"><Invoice /></div>;
            case "Dashboard":
                return <div id="divDashboard"><Dashboard /></div>
            default:
                return null;
        }
    }
    
    return (
        <div>
            
            <div className="app-container bg-light">
                <Nav showHideMenu={showHideMenu} />
                
                <div id="layoutSidenav">
                    
                    <div id="layoutSidenav_nav">
                        <Menu />
                    </div>
 
                    <div id="layoutSidenav_content">
                        {ReturnMenu()}

                        <div id="Footer">
                            <Footer />
                        </div>
                    </div>

                </div>
                
            </div>
            
            {/*
            {users.length &&
                <ul>
                    {users.map(user =>
                        <li key={user.id}>{user.firstName} {user.lastName}</li>
                    )}
                </ul>
            }
            */}
            
            {users.loading && <div className="spinner-border spinner-border-sm"></div>}
            {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}
        
        
        </div>
    );
}
