import { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';

export { Nav };

function Nav(prop) {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    if (!authUser) return null;

    return (
        <>
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-gradient-1">
            
            <a className="navbar-brand ps-3 nav-header" href="index.html">Teleradiology</a>
            
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" onClick={prop.showHideMenu}><i className="fa fa-bars" aria-hidden="true"></i></button>
            
            <ul className="navbar-nav ms-auto ms-md-0 me-lg-4 margin-auto">
                <img src="../../img/profile-pink.png" height="25px" width="25px" alt="" className=" mt-2 me-1"  />
                <div className="text-white mt-2 font-roboto font-weight400 profile_text">{authUser?.user_title} {authUser?.user_name}</div>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" name="navbarDropdown" href=" " role="button" data-bs-toggle="dropdown" aria-expanded="false"> </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        {/*<li><a className="dropdown-item" href="/">Home</a></li>
                        <li><a className="dropdown-item" href="/about">About</a></li>
                        
                        <li><a className="dropdown-item" href="/profile">Profile</a></li>*/}
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" href="#!"  onClick={logout}>Logout</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
        

        
        </>
    );
}