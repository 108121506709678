import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { authActions } from '_store';

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        //console.log('submit click');
        return dispatch(authActions.login({ username, password }));
    }

    return (
        
        <div id="page-top" className="attn-top-fixed bg-gradient-attendance">
        <div className="bg-attendance-overlay"></div>
         <div className="tele-topbar semi-circle tele-top-fixed tele-expand">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 pt-44 pb-4 login-header"><p className="text-white">Teleradiology</p></div>
                  
               </div>
            </div>
         </div>

        <div className="mt-130">
         <div className="container">
            <div className="login-container">
               <div className="container px-4">
                  <div className="row gx-4 justify-content-center">
                     <div className="col-lg-7 col-sm-10">
                        <div className="card-login o-hidden shadow-lg border-0">
                           <div className="card-body p-0">
                              <div className="row">
                                 <div className="col-lg-12 d-none d-sm-block bg-login-image login-card-body-padding pt-4 pb-4 ">
                                   <div className="login-left"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-5 col-sm-10">
                        <div className="card-login o-hidden shadow-lg border-0">
                           <div className="card-body p-0">
                              <div className="row">
                                 <div className="col-lg-12 pt-4 pb-4 login-card-body-padding">
                                   <h4 className="font-weight-light login-text">Login</h4>

                                   <form onSubmit={handleSubmit(onSubmit)}>
                        
                                   <div className="input-group mt-4 mb-4">
                                      <span className="input-group-text">
                                        <i className="bi bi-person-fill icon-text"></i>
                                      </span>
                                      <input name="username" type="text" {...register('username')} className="form-control" placeholder="Email" aria-label="Input group example" aria-describedby="basic-addon1" />
                                      
                                    </div>
                                   <div className="input-group">
                                       <span className="input-group-text font-size20 text-blueeye"><i className="bi bi-lock-fill icon-text"></i></span>
                                       <input name="password" type="password" {...register('password')} className="form-control" placeholder="Password" />
                                   </div>
                                   <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <button disabled={isSubmitting} className="btn btn-secondary btn-block text-light bi bi-box-arrow-in-right">
                                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            Login
                                        </button>
                                        {authError &&
                                            <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                                        }
                                   </div>
                                   </form>
                                   <div className="small pt-2"><a className="login-link" href=" #"><small>Forgot Password?</small></a></div>

                                   
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
        </div>
        </div>

        <footer className="py-3 bg-telefooter login-footer">
            <div className="container px-4">
            <p className="m-0 text-center text-sm text-footer">Copyright &copy; MYTSOFT 2022-2023 | All Rights Reserved</p>
            </div>
        </footer>

        </div>
    )
}
